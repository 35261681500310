<template>
  <div id="about-view">
    <div class="about-section">
      <div class="fs-1 text-center fw-bold">Dubu Kang</div>
      <div class="fs-1 text-center fw-bold">두부 강</div>
      <div class="fs-6 text-center">(Tofu River)</div>
      <div id="about-view-table">
        <div class="table-responsive">
          <table class="table">
            <thead class="table-primary">
              <tr>
                <th class="flex-fill">English</th>
                <th>Romanized</th>
                <th>Korean</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-active">
                <td>Tofu River</td>
                <td>Dubu Kang</td>
                <td>두부 강</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="about-section">
      <div class="fs-4 text-start fw-bold">Why the Name Dubu Kang?</div>
      <div class="fs-6 text-start">Dubu is a fun sounding word (do-boo) so I chose it as a baseline for the domain name.</div>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import "@/scss/variables.scss";

#about-view {
  margin: 2rem auto;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;

  .about-section {
    padding: 0 2rem 2rem 2rem;
    background-color: $background;
  }

  #about-view-table {
    display: flex;
    justify-content: center;
    justify-self: center;
    margin-top: 1rem;

    .table {
      margin: 0;
    }

    div {
      margin: 0 1rem;
      width: 100%;
    }

    td {
      width: 33%;
    }
  }
}
</style>
